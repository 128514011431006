<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Новая инструкция"
        create
        url-update="/hint/update/:id"
        url-back="/hint"
    >
        <job-hint-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import JobHintForm from '@/components/forms/JobHintForm.vue'
export default {
    name: 'HintCreate',
    components: { FormView, JobHintForm }
}
</script>